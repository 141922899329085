import classes from "./OverviewDetails.module.css";

const OverviewDetails = (props) => {
  
  const title = !props.inverse ? "Instant Time Tracking" : "Personalized Settings. Organize Your Tasks"
  const description = !props.inverse ? "Start the timer and begin tracking your time right now. Our intuitive timer enables you to easily monitor the time spent on various activities." : "Make the app truly yours with a lot of customization options. Choose from a wide range of colors and icons for easy differentiation and quick location of your activities."
  return (
    <div className={props.inverse ? classes.containerInverse : classes.container}>
      <p className={classes.title}>{title}</p>
      <p className={classes.description}>
      {description}
      </p>
    </div>
  );
};

export default OverviewDetails;