import classes from "./Main.module.css";
import Screenshot from "./Main/Screenshot";
import MailForm from "./Main/MailForm";

const Main = () => {
  return (
    <div className={classes.container}>
        <Screenshot />
        <MailForm />
    </div>
  );
};

export default Main;
