import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Helmet>
        <title>Trackly</title>
        <meta name="description" content="Simple tracker to control your time. Improve your productivity and motivation." />
        <meta name="keywords" content="Trackly, time tracker, time, tracker, productivity, motivation, task, focuse, goal, habit, schedule, planner, reminder" />
        <meta property="og:title" content="Trackly - Time Tracker" />
        <meta property="og:description" content="Simple tracker to control your time. Improve your productivity and motivation." />
        {/* <meta property="og:image" content="URL изображения для предпросмотра" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="URL вашей страницы" />
        <meta name="twitter:title" content="Моя страница - Описание сайта" />
        <meta name="twitter:description" content="Описание моей страницы" />
        <meta name="twitter:image" content="URL изображения для предпросмотра" />
        <meta name="twitter:card" content="summary_large_image" /> 
      </Helmet> */}
    <App />
  </React.StrictMode>
);

