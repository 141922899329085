import { useState } from "react";
import classes from "./MailForm.module.css";
import ReactGA from "react-ga4";
import MobileStoreButton from "react-mobile-store-button";

const MailForm = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const [isSave, setIsSave] = useState(false);

  const iOSUrl =
    "https://apps.apple.com/ua/app/trackly-activity-time-tracker/id1641017528";

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setIsValidEmail(true);
    setIsSave(false);
  };

  const onSubmit = () => {
    const isEmailValid = /\S+@\S+\.\S+/.test(email);
    setIsValidEmail(isEmailValid);

    if (isEmailValid) {
      ReactGA.event({
        category: "email",
        action: "email send",
      });
      window.gtag("event", "conversion", {
        send_to: "AW-11106651427/2woxCOyrsKUYEKOaiLAp",
      });

      fetch("/api/landing/email", {
        method: "POST",
        body: JSON.stringify({ email: email }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          console.log("success");
          setIsSave(true);
          setEmail("");
        }
      });
    }
  };

  return (
    <div className={classes.container}>
      {/* <p className={classes.description}>― Comming soon</p> */}
      <p className={classes.title}>
        {/* Get Notified
        <br />
        When we Launch */}
        Stay in control of your time with us.
      </p>
      {/* <div className={classes.mailForm}>
        <input
          className={isValidEmail ? classes.textField : classes.errorTextField}
          onChange={handleEmailChange}
          value={email}
          name="email"
          type="email"
          placeholder="Enter your email address"
          autocomplete="email"
        />
        <button className={classes.button} onClick={onSubmit}>
          Notify Me
        </button>
      </div> */}

      <div className={classes.appStoreButton}>
        <MobileStoreButton
          store="ios"
          url={iOSUrl}
          linkProps={{ title: "iOS Store Button" }}
          height={35}
        />
      </div>
      {isSave && <p className={classes.confirm}>Done!</p>}
    </div>
  );
};

export default MailForm;
