import classes from "./Header.module.css";

const Header = (props) => {

  const handleOverview = () => {
    props.scrollOverview()
  }

  const handleContactUs = () => {
    window.location.href ='mailto:trackly.tracker@gmail.com'
  }

  return (
    <div className={classes.container}>
      <a href="/">
        <div className={classes.titleContainer}>
          <img className={classes.titleIcon} src={"logo_title.png"} alt="" />
          <p className={classes.title}>Trackly</p>
        </div>
      </a>

      <button className={classes.button} onClick={props.scrollOverview}>Overview</button>
      <button className={classes.button} onClick={handleContactUs}>Contact us</button>
    </div>
  );
};

export default Header;
